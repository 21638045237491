import { FormGroup } from "@angular/forms";

export function loadTempalteAnimatoion() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
        let src = scripts[i].getAttribute('src') ;
        if (src != null && src.includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["assets/vendor/bootstrap/js/bootstrap.bundle.js",
      "assets/vendor/aos/aos.js",
  "assets/vendor/swiper/swiper-bundle.min.js",
"assets/vendor/purecounter/purecounter.js",
"assets/vendor/isotope-layout/isotope.pkgd.min.js",
"assets/vendor/glightbox/js/glightbox.min.js",
"assets/js/main.js",
"assets/vendor/google-charts/loader.js"];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
}

export function resetForm(form: FormGroup) {

    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key)?.setErrors(null) ;
    });
}