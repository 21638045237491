import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { loadTempalteAnimatoion, resetForm } from 'src/helpers/extensions';
import { DataService } from './services/data.service';
import { NavigationEnd, Router } from '@angular/router';
import { ContactUsEmail } from './interfaces/IContactUs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'SkilledMonks';

  constructor(
    private fb: FormBuilder,
    private dataServices: DataService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.isHome = event.url !== '/list-monk-pricing';
      }
    });
  }

  isHome: boolean = true;

  msg: string = '';
  error: string = '';
  wait: string = '';

  contactForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    subject: ['', Validators.required],
    message: [null, Validators.required],
  });

  ngOnInit(): void {
    loadTempalteAnimatoion();
  }

  onSave() {
    this.msg = '';
    this.error = '';
    if (this.contactForm.pristine) return;

    if(!this.contactForm.valid){
      this.error = 'All fields are required!';
      return;
    }

    this.wait = 'Sending....';

    let formValue = this.contactForm.value;

    let data: ContactUsEmail = {
      metadata: {
        to: 'gog1withme@gmail.com',
        toName: 'Admin - Skilled Monks',
        fromName: formValue.name,
        fromEmail: formValue.email,
        fromSubject: formValue.subject,
        fromMessage: formValue.message
      }
    }

    this.dataServices.submitContact(data).subscribe(
      (data) => {
        if (data.success) {
          this.wait = '';
          this.msg = 'We will contact you shortly. Thank you!';
          resetForm(this.contactForm);
        } else {
          this.emailFailed();
        }
      },
      (err) => {
        this.emailFailed();
      }
    );
  }


  emailFailed() {
    this.error =
      'Oops! Something is wrong with our server, contact us @ SkilledMonks@gmail.com';
  }
}
