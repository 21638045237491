<!-- ======= Pricing Section ======= -->
<section id="pricing" class="pricing">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Pricing</h2>
            <p style="padding-bottom:20px ;">List Monk Pricing</p>
        </header>

        <div class="row gy-4" data-aos="fade-left">

            <div class="col-lg-12 col-md-12" data-aos="zoom-in" data-aos-delay="100">
                <div class="box">
                    <h3 style="color: #07d5c0;">Professional Plan</h3>
                    <div class="price"><sup>$</sup>45<span> / month</span></div>
                    <img style="max-height: 200px;" src="assets/img/rocket1.jpg" class="img-fluid" alt="">
                    <ul>
                        <li>Custom email volume</li>
                        <li>Advanced email editor</li>
                        <li>Marketing automation</li>
                        <li>Advanced reporting and analytics</li>
                        <li>Advanced features and support</li>
                        <li>And more</li>

                    </ul>
                    <a href="#contact" class="btn-buy scrollto">Buy
                        Now</a>
                </div>
            </div>
        </div>

    </div>

</section><!-- End Pricing Section -->
<!-- ======= Features Section ======= -->
<section id="features" class="features">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Features</h2>
            <p>List Monk Features</p>
        </header>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/email-rocket.jpg" class="img-fluid" alt="">
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
                <div class="row align-self-center gy-4">

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Email scheduling</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Bulk users list import & export </h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Reporting and analytics</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>SMTP and SendGrid support</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Custom templates</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Maintenance & support</h3>
                        </div>
                    </div>

                </div>
            </div>

        </div> <!-- / row -->


    </div>

</section><!-- End Features Section -->