<!-- ======= Header ======= -->
<header id="header" class="header fixed-top">
  <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

    <a href="index.html" class="logo d-flex align-items-center">
      <img src="assets/logo/logo.png" alt="">
      <span>Skilled Monks</span>
    </a>

    <nav id="navbar" class="navbar">
      <ul>
        <ng-container *ngIf="isHome">
          <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a class="nav-link scrollto" href="#about">About</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
          <li><a class="nav-link scrollto" href="#portfolio">Portfolio</a></li>
        </ng-container>
        <ng-container *ngIf="!isHome">
          <li><a class="nav-link scrollto active" href="/">Home</a></li>
        </ng-container>

        <li><a class="nav-link active" href="/list-monk-pricing">Email Marketing Tool (List Monk)</a></li>
        <!-- <li><a class="nav-link scrollto" href="#team">Team</a></li> -->
        <li><a class="getstarted scrollto" href="#contact">Contact</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav><!-- .navbar -->

  </div>
</header><!-- End Header -->

<!-- ======= Hero Section ======= -->
<section id="hero" class="hero d-flex align-items-center">

  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex flex-column justify-content-center">
        <h1 data-aos="fade-up">{{isHome? "Your Creative Technology Partner for": "List Monk - Send Bulk Emails"}}
        </h1>
        <h2 data-aos="fade-up" data-aos-delay="400">{{isHome? "Software Development & Innovation": "You will get
          features like email templates, campaign , subscribers, SMTP & SendGrid integration and automation"}}</h2>
        <div data-aos="fade-up" data-aos-delay="600">
          <div class="text-center text-lg-start">
            <a href="#contact"
              class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
              <span>{{isHome? "Get in Touch": "Schedule Demo"}}</span>
              <i class="bi bi-arrow-right"></i>
            </a>

            <div class="social-links mt-3">
              <h4 *ngIf="isHome">Or</h4>
              <a *ngIf="isHome" href="https://www.upwork.com/freelancers/~010be3900bbd75b08d?viewMode=1" target="_blank"
                class="linkedin"><i class="bi bi-work">Hire our top developer on UpWork</i></a>
              <!-- <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram bx bxl-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin bx bxl-linkedin"></i></a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
        <img [src]="isHome?'assets/img/hero-img.png':'assets/img/listmonk.png'" class="img-fluid" alt="">
      </div>
    </div>
  </div>

</section><!-- End Hero -->

<main id="main">
  <!-- ======= About Section ======= -->

  <router-outlet></router-outlet>

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </header>

      <div class="row gy-4">

        <div class="col-lg-6">

          <div class="row gy-4">
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p>Sector 31,<br>Gurgaon, India, 122001</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+91 991 173 1169</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>info@skilledmonks.com</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-clock"></i>
                <h3>Open Hours</h3>
                <p>Monday - Friday<br>9:00AM - 10:00PM (IST)</p>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6">
          <form [formGroup]="contactForm" (submit)="onSave()" class="php-email-form">
            <div class="row gy-4">

              <div class="col-md-6">
                <input type="text" matInput formControlName="name" class="form-control" placeholder="Your Name*"
                  required>
              </div>

              <div class="col-md-6 ">
                <input type="email" class="form-control" matInput formControlName="email" placeholder="Your Email*"
                  required>
              </div>

              <div class="col-md-12">
                <input type="text" class="form-control" matInput formControlName="subject" placeholder="Subject*"
                  required>
              </div>

              <div class="col-md-12">
                <textarea class="form-control" matInput formControlName="message" rows="6" placeholder="Message*"
                  required></textarea>
              </div>

              <div class="col-md-12 text-center">
                <div class="loading">Loading</div>
                <div class="error-message" *ngIf="error!=''">{{error}}</div>
                <div class="sent-message" *ngIf="msg!=''">{{msg}}</div>
                <div class="sent-message" *ngIf="wait!=''">{{wait}}</div>

                <button type="submit">Send Message</button>
              </div>

            </div>
          </form>

        </div>

      </div>

    </div>

  </section><!-- End Contact Section -->

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer" class="footer">


  <div class="footer-top">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-5 col-md-12 footer-info">
          <a href="index.html" class="logo d-flex align-items-center">
            <img src="assets/img/SkilledMonks.jpg" alt="">
            <span>Skilled Monks</span>
          </a>
          <p>Your Creative Technology Partner for
            Software Development & Innovation</p>
          <div class="social-links mt-3">
            <a href="https://www.upwork.com/freelancers/~010be3900bbd75b08d?viewMode=1" target="_blank"
              class="linkedin"><i class="bi bi-work">Hire our top developer on UpWork</i></a>
            <!-- <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram bx bxl-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bi bi-linkedin bx bxl-linkedin"></i></a> -->
          </div>
        </div>

        <div class="col-lg-2 col-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#about">About us</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#services">Services</a></li>
            <!-- <li><i class="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li> -->
          </ul>
        </div>

        <div class="col-lg-2 col-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Consulting</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Product Management</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Web Development</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Software Development</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">APP Development</a></li>
            <li><i class="bi bi-chevron-right"></i> <a href="#">Cloud solutions</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          <h4>Contact Us</h4>
          <p>
            Sector 31 <br>
            Gurgaon, 122001<br>
            India <br><br>
            <strong>Phone:</strong> +91 991 173 1169<br>
            <strong>Email:</strong> info@SkilledMonks.com<br>
          </p>

        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>Skilled Monks</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
    </div>
  </div>
</footer><!-- End Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
    class="bi bi-arrow-up-short"></i></a>