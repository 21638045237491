<section id="about" class="about">

    <div class="container" data-aos="fade-up">
        <div class="row gx-0">

            <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                <div class="content">
                    <h3>Who We Are</h3>
                    <h2>We are a group of innovative Software Developers known as SkilledMonks. Top Rated on UpWork!
                    </h2>
                    <p>
                        We, SkilledMonks tailor the software development process to your specific business needs to
                        deliver
                        high-quality software on time and within budget.
                    </p>
                    <div class="text-center text-lg-start">
                        <a href="#"
                            class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                            <span>Read More</span>
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                <img src="assets/img/about.jpg" class="img-fluid" alt="">
            </div>

        </div>
    </div>

</section>


<!-- End About Section -->

<!-- ======= Values Section ======= -->
<section id="values" class="values">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Our Development Approaches</h2>
            <p>DEVELOPMENT APPROACHES WE FOLLOW</p>
        </header>

        <div class="row">

            <div class="col-lg-4">
                <div class="box" data-aos="fade-up" data-aos-delay="200">
                    <img src="assets/img/values-1.png" class="img-fluid" alt="">
                    <h3>Agile</h3>
                    <p>The Agile methodology allows us to release reliable software fast and introduce changes easily.
                        The
                        development process consists of 1-4 week iterations resulting in an evolved version of software
                        shipped
                        each time. After each iteration, we get real user feedback that drives informed decisions on the
                        next
                        development steps.

                        Note: Although the Agile approach is viable in many cases, we can also adopt Waterfall and other
                        approaches depending on a customer’s objectives. Check out our overview of all possible software
                        development life cycle (SDLC) models.</p>
                </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="400">
                    <img src="assets/img/values-2.png" class="img-fluid" alt="">
                    <h3>DevOps</h3>
                    <p>With an established DevOps culture at ScienceSoft, we streamline software delivery and improve
                        its
                        quality with the following DevOps best practices:

                        Infrastructure as Code (IaC): automating the creation of new infrastructures and keeping them
                        consistent
                        with each other to ensure smooth software deployment.
                        Continuous Integration/Continuous Delivery or Continious Deployment (CI/CD): automating
                        integration and
                        deployment of code changes.
                        Automated testing: automating regression, performance, and other types of testing that are
                        continuously
                        carried out within the CI/CD pipeline.
                        Application performance monitoring: configuring application performance monitoring tools to
                        detect
                        post-release bugs quickly.</p>
                </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="600">
                    <img src="assets/img/values-3.png" class="img-fluid" alt="">
                    <h3>Cloud-native development</h3>
                    <p>When building applications in the cloud, we take advantage of reduced software development and
                        operation
                        costs, easy scalability and access to advanced cloud services that enable implementation of
                        cutting-edge
                        techs (AI, IoT, data science, and more).</p>
                </div>
            </div>

        </div>

    </div>

</section><!-- End Values Section -->

<!-- ======= Counts Section ======= -->
<section id="counts" class="counts">
    <div class="container" data-aos="fade-up">

        <div class="row gy-4">

            <div class="col-lg-3 col-md-6">
                <div class="count-box">
                    <i class="bi bi-emoji-smile"></i>
                    <div>
                        <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1"
                            class="purecounter"></span>
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="count-box">
                    <i class="bi bi-journal-richtext" style="color: #ee6c20;"></i>
                    <div>
                        <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1"
                            class="purecounter"></span>
                        <p>Projects</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="count-box">
                    <i class="bi bi-headset" style="color: #15be56;"></i>
                    <div>
                        <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                            class="purecounter"></span>
                        <p>Hours Of Support</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="count-box">
                    <i class="bi bi-people" style="color: #bb0852;"></i>
                    <div>
                        <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1"
                            class="purecounter"></span>
                        <p>Hard Workers</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section><!-- End Counts Section -->

<!-- ======= Features Section ======= -->
<section id="features" class="features">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Development services</h2>
            <p>Our diverse software
                development services</p>
        </header>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/features.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
                <div class="row align-self-center gy-4">

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Software consulting</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Custom software development</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Enterprise software solution</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Software integration</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Offshore development center</h3>
                        </div>
                    </div>

                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                        <div class="feature-box d-flex align-items-center">
                            <i class="bi bi-check"></i>
                            <h3>Maintenance & support</h3>
                        </div>
                    </div>

                </div>
            </div>

        </div> <!-- / row -->

        <!-- Feature Tabs -->
        <div class="row feture-tabs" data-aos="fade-up">
            <div class="col-lg-6">
                <h3>Software development expertise
                    across various technologies</h3>

                <!-- Tabs -->
                <ul class="nav nav-pills mb-3">
                    <li>
                        <a class="nav-link active" data-bs-toggle="pill" href="#tab1">Web development</a>
                    </li>
                    <li>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab2">eCommerce development</a>
                    </li>
                    <li>
                        <a class="nav-link" data-bs-toggle="pill" href="#tab3">Support & maintenance</a>
                    </li>
                </ul><!-- End Tabs -->

                <!-- Tab Content -->
                <div class="tab-content">

                    <div class="tab-pane fade show active" id="tab1">
                        <p>With the help of our highly qualified & experienced developers we deliver highly customized
                            web
                            applications to our clients. We provide web applications of various types such as CMS,
                            eCommerce web
                            portal, and enterprise web development.</p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>Custom web development</h4>
                        </div>
                        <p>We develop tailor-made Customized Web Application Development for all the niches.</p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>Web portal development</h4>
                        </div>
                        <p>We develop customer-focused web portals that comprise all the necessary functionalities.</p>

                    </div><!-- End Tab 1 Content -->

                    <div class="tab-pane fade show" id="tab2">
                        <p>Our Team is adept in developing eCommerce-centric Web application development.

                        </p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>CMS web development</h4>
                        </div>
                        <p>Our developers develop CMS web applications which are technically complex yet easy-to-use.
                        </p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>Enterprise web development</h4>
                        </div>
                        <p>We develop several custom B2B & B2C portals, intranets, media, and Enterprise Information
                            Systems.</p>
                    </div><!-- End Tab 2 Content -->

                    <div class="tab-pane fade show" id="tab3">
                        <p>We provide unmatched maintenance services to enhance your web application performance.</p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>Corrective</h4>
                        </div>
                        <p>Fixing problems discovered by users or identified during monitoring.</p>
                        <div class="d-flex align-items-center mb-2">
                            <i class="bi bi-check2"></i>
                            <h4>Adaptive</h4>
                        </div>
                        <p>Keeping software up-to-date by tuning it in line with the changing business needs.</p>
                    </div><!-- End Tab 3 Content -->

                </div>

            </div>

            <div class="col-lg-6">
                <img src="assets/img/features-2.png" class="img-fluid" alt="">
            </div>

        </div><!-- End Feature Tabs -->

        <!-- Feature Icons -->
        <div class="row feature-icons" data-aos="fade-up">
            <h3>Our software
                development cycle</h3>

            <div class="row">

                <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                    <img src="assets/img/features-3.png" class="img-fluid p-4" alt="">
                </div>

                <div class="col-xl-8 d-flex content">
                    <div class="row align-self-center gy-4">

                        <div class="col-md-6 icon-box" data-aos="fade-up">
                            <i class="ri-line-chart-line"></i>
                            <div>
                                <h4>Discovery</h4>

                                <ul>
                                    <li>
                                        Conduct research
                                    </li>
                                    <li>
                                        Conduct workshop
                                    </li>
                                    <li>
                                        Establish vision & direction of end product
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                            <i class="ri-stack-line"></i>
                            <div>
                                <h4>Initiation</h4>

                                <ul>
                                    <li>Kickoff the proceedings</li>
                                    <li>
                                        Adapt yourself to new environment
                                    </li>
                                    <li>
                                        Establish a process
                                    </li>
                                </ul>


                            </div>
                        </div>

                        <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="ri-brush-4-line"></i>
                            <div>
                                <h4>Delivery
                                </h4>

                                <ul>
                                    <li> Execute each sprint phase</li>
                                    <li>Execute phases like analysis & design
                                    </li>
                                    <li>Execute development & testing </li>
                                </ul>


                            </div>
                        </div>

                        <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                            <i class="ri-magic-line"></i>
                            <div>
                                <h4>Maintenance</h4>

                                <ul>
                                    <li>
                                        24*7 maintenance service
                                    </li>
                                    <li>
                                        Continuous evolution
                                    </li>
                                    <li>
                                        Positive approach to develop a product
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <!-- <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="ri-command-line"></i>
                <div>
                  <h4>Molestiae dolor</h4>
                  <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
                </div>
              </div>

              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                <i class="ri-radar-line"></i>
                <div>
                  <h4>Explicabo consectetur</h4>
                  <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
                </div>
              </div> -->

                    </div>
                </div>

            </div>

        </div><!-- End Feature Icons -->

    </div>

</section><!-- End Features Section -->

<!-- ======= Services Section ======= -->
<section id="services" class="services">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Services</h2>
            <p>Our Software Application Development Services</p>
        </header>

        <div class="row gy-4">

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                <div class="service-box blue">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Software Consulting</h3>
                    <p>Our Software Development Experts understand your idea, target users and monetization strategy to
                        provide
                        you with a wide range of solutions.
                    </p>
                    <ul>
                        <li>
                            Consultation on idea discovery, technology insights and risk analysis
                        </li>
                        <li>
                            Creating a growth strategy from user acquisition to monetization
                        </li>
                        <li>
                            Detailed Proposal with estimates for the scope of work
                        </li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <div class="service-box orange">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Custom Software Development</h3>
                    <p>We convert client requirements into a custom software solution with advanced technologies by
                        focusing on
                        building secure, scalable and custom-centric software products.</p>
                    <ul>
                        <li>
                            Mobile Apps for iOS and Android using Ionic framework
                        </li>
                        <li>
                            Backend Solutions deployed on Amazon, Firebase or Azure Cloud services
                        </li>
                        <li>
                            Web Apps using MEAN, React, Ionic, Node, .Net and Angular frameworks
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div class="service-box green">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Enterprise Software Solutions</h3>
                    <p>We improve business agility and efficiency by building cutting-edge mobile and web software
                        solutions for
                        enterprises.</p>
                    <ul>
                        <li>
                            Workflow Management Solutions
                        </li>
                        <li>
                            CRM Solutions
                        </li>
                        <li>
                            ERP Software Solutions & Document Management Solutions
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                <div class="service-box red">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Application Services</h3>
                    <p>We provide a complete set of services around the development and maintenance of custom
                        business-centric
                        applications.</p>
                    <ul>
                        <li>
                            App Development & Management
                        </li>
                        <li>
                            App Modernization & Integration
                        </li>
                        <li>
                            Application Testing & Security Services
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                <div class="service-box purple">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Team Extension</h3>
                    <p>We allow our clients to extend their teams with our experts and follow our clients’ development
                        methodologies, practices and work culture.</p>
                    <ul>
                        <li>
                            Working in Client’s Timezone
                        </li>
                        <li>
                            Domain and Technology Expertise Acquisition
                        </li>
                        <li>
                            Real-time Check on the work progress
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                <div class="service-box pink">
                    <i class="ri-discuss-line icon"></i>
                    <h3>Maintenance & Support</h3>
                    <p>By providing support and maintenance, we minimize issues and maximize efficiency.</p>
                    <ul>
                        <li>
                            Adaptive Maintenance and Support
                        </li>
                        <li>
                            Preventive Maintenance and Support
                        </li>
                        <li>
                            Corrective Maintenance and Support
                        </li>
                        <li>
                            Perfective Maintenance and Support
                        </li>
                    </ul>
                </div>
            </div>

        </div>

    </div>

</section><!-- End Services Section -->

<!-- ======= Pricing Section ======= -->
<section id="pricing" class="pricing">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Pricing</h2>
            <p>Check our Pricing</p>
        </header>

        <div class="row gy-4" data-aos="fade-left">

            <div class="col-lg-12 col-md-12" data-aos="zoom-in" data-aos-delay="100">
                <div class="box">
                    <h3 style="color: #07d5c0;">Fullstack developer</h3>
                    <div class="price"><sup>$</sup>55<span> / hour</span></div>
                    <img src="assets/img/pricing-free.png" class="img-fluid" alt="">
                    <ul>
                        <li>Angular,
                            React,
                            Ionic,
                            Node,
                            .Net,
                            AWS,
                            Firebase</li>

                    </ul>
                    <a href="https://www.upwork.com/o/companies/~01f6df7e2124db34e0/" target="blank"
                        class="btn-buy">Hire
                        Now</a>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
          <div class="box">
            <span class="featured">Featured</span>
            <h3 style="color: #65c600;">Starter Plan</h3>
            <div class="price"><sup>$</sup>19<span> / mo</span></div>
            <img src="assets/img/pricing-starter.png" class="img-fluid" alt="">
            <ul>
              <li>Aida dere</li>
              <li>Nec feugiat nisl</li>
              <li>Nulla at volutpat dola</li>
              <li>Pharetra massa</li>
              <li class="na">Massa ultricies mi</li>
            </ul>
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
          <div class="box">
            <h3 style="color: #ff901c;">Business Plan</h3>
            <div class="price"><sup>$</sup>29<span> / mo</span></div>
            <img src="assets/img/pricing-business.png" class="img-fluid" alt="">
            <ul>
              <li>Aida dere</li>
              <li>Nec feugiat nisl</li>
              <li>Nulla at volutpat dola</li>
              <li>Pharetra massa</li>
              <li>Massa ultricies mi</li>
            </ul>
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
          <div class="box">
            <h3 style="color: #ff0071;">Ultimate Plan</h3>
            <div class="price"><sup>$</sup>49<span> / mo</span></div>
            <img src="assets/img/pricing-ultimate.png" class="img-fluid" alt="">
            <ul>
              <li>Aida dere</li>
              <li>Nec feugiat nisl</li>
              <li>Nulla at volutpat dola</li>
              <li>Pharetra massa</li>
              <li>Massa ultricies mi</li>
            </ul>
            <a href="#" class="btn-buy">Buy Now</a>
          </div>
        </div> -->

        </div>

    </div>

</section><!-- End Pricing Section -->

<!-- ======= F.A.Q Section ======= -->
<section id="faq" class="faq">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
        </header>

        <div class="row">
            <div class="col-lg-12">
                <!-- F.A.Q List 1-->
                <div class="accordion accordion-flush" id="faqlist1">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#faq-content-1">
                                What should I look for in a software application development company?
                            </button>
                        </h2>
                        <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div class="accordion-body">
                                Once you know what the services offered by a software development company are, now you
                                need to
                                understand how to find the right company for your project requirements. Here is a
                                checklist of things
                                you should consider before hiring the software development services company:
                                <ul>
                                    <li>
                                        Does the company has experience related to your project? Check their work and
                                        ask for similar
                                        projects they have worked on in the past. Read the company’s reviews on
                                        GoodFirms or Clutch.
                                    </li>
                                    <li>
                                        Does the software development company have expertise in the technologies you
                                        require? For example,
                                        if you need to develop an IoT product, check if they provide IoT development
                                        services.
                                    </li>
                                    <li>
                                        Consider the company’s team size. If you pick a company that is too large,
                                        there’s a risk that you
                                        might not get enough attention. Therefore, ensure to work with a company that
                                        has the team size
                                        based on your project requirements.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#faq-content-2">
                                What do we do as a software development company?
                            </button>
                        </h2>
                        <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                            <div class="accordion-body">
                                As a software development company, our aim is to build software or software applications
                                that include
                                identifying a need, designing the software, coding it and testing for the issues.
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </div>

</section><!-- End F.A.Q Section -->

<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Portfolio</h2>
            <p>Check our latest work</p>
        </header>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                    <li data-filter="*" class="filter-active">All</li>
                    <li data-filter=".filter-app">App</li>
                    <li data-filter=".filter-card">Card</li>
                    <li data-filter=".filter-web">Web</li>
                </ul>
            </div>
        </div>

        <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-10.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>ZPopular</h4>
                        <p>https://www.zpopular.com</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-10.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="ZPopular"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://www.zpopular.com" title="More Details"><i
                                    class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-11.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>Spiro.media</h4>
                        <p>https://www.spiro.media</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-11.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="ZPopular"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://spiro.media/" title="More Details"><i
                                    class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>Dr. Hess</h4>
                        <p>https://drhess.com/</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="App 1"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://drhess.com/" title="More Details"><i
                                    class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>          

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>Project Progress Platform</h4>
                        <p>https://projects-progress-platform.web.app/</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="App 2"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://projects-progress-platform.web.app/"
                                title="More Details"><i class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>Explainer Page</h4>
                        <p>https://www.explainerpage.com/</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="Card 2"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://www.explainerpage.com/" title="More Details"><i
                                    class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                <div class="portfolio-wrap">
                    <img src="assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
                    <div class="portfolio-info">
                        <h4>Business Stunt</h4>
                        <p>https://businessstunt.com</p>
                        <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery"
                                class="portfokio-lightbox" title="businessstunt"><i class="bi bi-plus"></i></a>
                            <a target="_blank" href="https://businessstunt.com" title="More Details"><i
                                    class="bi bi-link"></i></a>
                        </div>
                    </div>
                </div>
            </div>

        

        </div>

    </div>

</section><!-- End Portfolio Section -->

<!-- ======= Testimonials Section ======= -->
<section id="testimonials" class="testimonials">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Testimonials</h2>
            <p>What they are saying about us</p>
        </header>

        <div class="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="200">
            <div class="swiper-wrapper">

                <div class="swiper-slide">
                    <div class="testimonial-item">
                        <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i>
                        </div>
                        <p>
                            "Arun is a great developer, he advised us to use Amplify for our project and accepted to set
                            on a long
                            teaching session with us.
                            He is very friendly and knowledgeable, what I liked about him the most is that if he doesn't
                            know
                            something he would simply tell you and will try to figure it out.
                            I recommend Arun for your next Amplify, React or Angular project. I will definitely hire him
                            again."
                        </p>
                        <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                            <h3>Sameer Alomari</h3>
                            <h4>AWS Architect</h4>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="swiper-slide">
                    <div class="testimonial-item">
                        <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i>
                        </div>
                        <p>
                            "Great AWS developer. Worked through a complex project and made it look easy. Highly
                            recommended."
                        </p>
                        <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                            <h3>Cory Hauber</h3>
                            <h4>CEO & Founder</h4>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="swiper-slide">
                    <div class="testimonial-item">
                        <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i>
                        </div>
                        <p>
                            "Incredible backend developer in AWS lambda emailing engine, nice smooth code and a pleasure
                            to work
                            with."
                        </p>
                        <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                            <h3>Michael Castleman</h3>
                            <h4>https://explainerpage.com Owner</h4>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="swiper-slide">
                    <div class="testimonial-item">
                        <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i>
                        </div>
                        <p>
                            "Brilliant work delivered consistently. Big Brain solutions."
                        </p>
                        <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
                            <h3>Matt Brandon</h3>
                            <h4>Freelancer</h4>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

                <div class="swiper-slide">
                    <div class="testimonial-item">
                        <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i
                                class="bi bi-star-fill"></i>
                        </div>
                        <p>
                            "Brilliant and fast coder, with incredible skills to handle complex problems with elegant
                            solutions."
                        </p>
                        <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                            <h3>Michael Castleman</h3>
                            <h4>https://explainerpage.com Owner</h4>
                        </div>
                    </div>
                </div><!-- End testimonial item -->

            </div>
            <div class="swiper-pagination"></div>
        </div>

    </div>

</section><!-- End Testimonials Section -->

<!-- ======= Team Section ======= -->
<!-- <section id="team" class="team">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <h2>Team</h2>
        <p>Our hard working team</p>
      </header>

      <div class="row gy-4">

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/team-1.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Walter White</h4>
              <span>Chief Executive Officer</span>
              <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum
                exercitationem iure minima enim corporis et voluptate.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/team-2.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Sarah Jhonson</h4>
              <span>Product Manager</span>
              <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis.
                Voluptate sed quas reiciendis animi neque sapiente.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/team-3.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>William Anderson</h4>
              <span>CTO</span>
              <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut
                architecto porro aspernatur molestiae modi.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/team-4.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Amanda Jepson</h4>
              <span>Accountant</span>
              <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut
                possimus ipsum officia.</p>
            </div>
          </div>
        </div>

      </div>

    </div>

  </section> -->
<!-- End Team Section -->

<!-- ======= Clients Section ======= -->
<!-- <section id="clients" class="clients">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <h2>Our Clients</h2>
        <p>Temporibus omnis officia</p>
      </header>

      <div class="clients-slider swiper-container">
        <div class="swiper-wrapper align-items-center">
          <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt=""></div>
          <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt=""></div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

  </section> -->
<!-- End Clients Section -->