import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactUsEmail } from '../interfaces/IContactUs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  
  constructor(private http: HttpClient) { }

  public submitContact(data: ContactUsEmail): Observable<{success: boolean}> {

    let url = "https://iwj8t3q352.execute-api.ap-south-1.amazonaws.com/dev/charge/contact_us";
    return this.http.post<{success: boolean}>(url, data);
  }

}

